import React from 'react';

const Assembly = ({ formId, onChange, value }) => (
  <div className="assemblyInput">
    <div className="form-group">
      <label htmlFor={`assembly__${formId}`}>Montaż</label>
      <select
        className="form-control"
        id={`assembly__${formId}`}
        name="assembly"
        onChange={onChange}
        value={value}
        required
      >
        <option value="" disabled>Wybierz</option>
        <option value="rivet">Nitonakrętka</option>
        <option value="expansionJoints">Kołki rozporowe</option>
      </select>
    </div>
  </div>
);

export default Assembly;
