import React from 'react';

export const GATE_DIRECTION_LEFT = 'L/PO';
export const GATE_DIRECTION_RIGHT = 'P/PO';
export const GATE_DIRECTION_LEFT_2 = 'L/UL';
export const GATE_DIRECTION_RIGHT_2 = 'P/UL';

const GatewayDirection = ({ formId, onChange, value }) => (
  <div className="form-group">
    <label htmlFor={`gateDirection__${formId}`}>Kierunek</label>
    <select
      className="form-control"
      id={`gateDirection__${formId}`}
      onChange={onChange}
      name="gateDirection"
      value={value || ''}
      required
    >
      <option value="" disabled>Wybierz</option>
      <option value={GATE_DIRECTION_LEFT}>L/PO</option>
      <option value={GATE_DIRECTION_RIGHT}>P/PO</option>
      <option value={GATE_DIRECTION_LEFT_2}>L/UL</option>
      <option value={GATE_DIRECTION_RIGHT_2}>P/UL</option>
    </select>
  </div>
);

export default GatewayDirection;
